import React from 'react';
import './scrollup.css';

const ScrollUp = () => {
    window.addEventListener("scroll", function(){
        const scrollUp = this.document.querySelector(".scrollup");
    })
  return (
    <a href="#" className="scrollup">
        <i className="uil uil-arrow-up scrollup__icon"></i>
    </a>
  )
}

export default ScrollUp