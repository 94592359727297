import Image1 from "../assets/Image1.jpg";
import Image2 from "../assets/Image2.jpg";
import Image3 from "../assets/Image3.jpg";

export const Data = [
  {
    id: 1,
    image: Image1,
    title: "Deepak Aacharya",
    description:
      "Suman's dedication, creativity, and collaborative nature make him an invaluable asset in any project.",
  },
  {
    id: 2,
    image: Image2,
    title: "Pradeep Karki",
    description:
      "Suman's passion, innovative thinking, and collaborative approach make him an invaluable asset to any team.",
  },
  {
    id: 3,
    image: Image3,
    title: "Manish Thapaliya",
    description:
      "Suman's technical expertise, problem-solving abilities, and dedication make him a valuable asset on any team.",
  },
];
